<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A reaction with a rate constant of
        <number-value :value="rateConstant" unit="\text{s}^{-1}" />
        is carried out at a temperature of
        <number-value :value="Temp" unit="^\circ\text{C.}" />
        Determine the activation energy,
        <stemble-latex content="$\text{E}_\text{a},$" />
        for the reaction in
        <stemble-latex content="$\text{kJ/mol}$" />
        if it has a frequency factor,
        <stemble-latex content="$\text{A,}$" />
        of
        <number-value :value="freqFactor" unit="\text{s}^{-1}." />
      </p>

      <calculation-input
        v-model="inputs.activationEnergy"
        prepend-text="$\text{E}_\text{a}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question97',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        activationEnergy: null,
      },
    };
  },
  computed: {
    rateConstant() {
      return this.taskState.getValueBySymbol('rateConstant').content;
    },
    Temp() {
      return this.taskState.getValueBySymbol('Temp').content;
    },
    freqFactor() {
      return this.taskState.getValueBySymbol('freqFactor').content;
    },
  },
};
</script>
